<template>
  <v-container class="app-context" fluid>
    <div class="context flex">
      <div v-if="selectItem === 2" class="hospital-list fill-width-list">
        <v-card
          elevation="0"
          class="reform_primary--text mr-5 req3-title-text"
          v-text="titleName"
        />
        <div class="notification req3-notification">
          <div class="patient-count req3-patient-count">
            <span
              class="text-h6 text-name-span req3-patient-text-name"
              v-text="textName"
            ></span>
            <span
              v-if="currentTab === 1"
              class="text-h6 text-name-span req3-patient-text"
            >
              {{ listStatus.totalDataCnt }}곳
            </span>
            <span v-else class="text-h6 text-name-span req3-patient-text">
              {{ listStatus.totalDataCnt }}곳
            </span>
            <h1
              v-if="currentTab === 1"
              style="line-height: 30px; color: #146f83"
            >
              ||
            </h1>
            <span v-text="textTempName" v-if="currentTab === 1"></span>
            <span v-if="currentTab === 1">{{ listStatus.tempCount }}명</span>
          </div>
        </div>
        <div v-if="list.length > 0 && !isChildLoading">
          <div class="scroll-body">
            <div class="scroll-content">
              <v-row no-gutters style="font-size: 19px; color: gray">
                <v-col cols="12">
                  <v-container fluid>
                    <v-row no-gutters>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 병원명 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="2">
                        <span> 주소 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 병원전화번호 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 팩스번호 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 의사명 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="2">
                        <span> 전문과목 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 졸업년도 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 수련병원 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 진료의뢰 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 보내기 </span>
                      </v-col>
                    </v-row>
                  </v-container>
                  <span style="border-bottom: 1px solid lightgray"> </span>
                </v-col>
              </v-row>
              <v-row
                style="font-size: 18px; border-bottom: 1px solid lightgray"
                v-for="(listItem, index) in list"
                :key="index + 'C'"
                class="receive-patient-list"
                @click="goPrView(listItem.drId, index)"
                no-gutters
              >
                <v-col cols="12">
                  <v-container fluid>
                    <v-row no-gutters>
                      <v-col cols="1" class="text-center">
                        {{ listItem.hospNm }}
                      </v-col>
                      <v-col cols="2">
                        <!-- 길이 나중에 고려하기 -->
                        {{ listItem.addr }} {{ listItem.addr2 }}
                      </v-col>
                      <v-col cols="1" class="text-center">
                        {{ listItem.hospTel }}
                      </v-col>
                      <v-col cols="1" class="text-center">
                        {{
                          listItem.hospFaxNo === null ||
                          listItem.hospFaxNo === ""
                            ? "없음"
                            : listItem.hospFaxNo
                        }}
                      </v-col>
                      <v-col cols="1" class="text-center">
                        {{ listItem.drNm }}
                      </v-col>
                      <v-col cols="2" class="text-center">
                        {{ listItem.proObj }}
                        {{
                          listItem.dtlMajor !== "" &&
                          listItem.dtlMajor !== undefined &&
                          listItem.dtlMajor !== null
                            ? "| " + listItem.dtlMajor
                            : ""
                        }}
                        {{
                          listItem.dtlProObj !== "" &&
                          listItem.dtlProObj !== undefined &&
                          listItem.dtlProObj !== null
                            ? "| " + listItem.dtlProObj
                            : ""
                        }}
                      </v-col>
                      <v-col cols="1" class="text-center">
                        {{
                          listItem.gradYear !== "" && listItem.gradYear !== null
                            ? "(" + listItem.gradYear + "년 졸업)"
                            : ""
                        }}
                      </v-col>
                      <v-col cols="1" class="text-center">
                        {{ listItem.trainHosp }}
                      </v-col>
                      <v-col
                        cols="1"
                        class="text-center"
                        @click="moveRequest(listItem.drId, index)"
                        @click.stop
                        style="cursor: pointer"
                      >
                        <img
                          src="@/assets/images/icons/description_black_24dp.svg"
                        />
                        <!-- <span class="material-icons-outlined header-icon"
                        >description</span
                      > -->
                      </v-col>
                      <v-col
                        cols="1"
                        class="text-center"
                        @click="writeMessage(listItem)"
                        @click.stop
                        style="cursor: pointer"
                      >
                        <img
                          src="@/assets/images/icons/mail_outline_black_24dp.svg"
                        />
                        <!-- <span class="material-icons-outlined header-icon"
                        >email</span
                      > -->
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-row justify="center" class="mt-5">
            <v-col cols="auto" class="text-center pagination-wrap pa-0 ma-0">
              <p
                class="navigation-act prev"
                v-if="listStatus.first > 5"
                @click="navigatorAct('prev')"
              >
                <!-- Prev -->
                <v-icon> mdi-chevron-left </v-icon>
              </p>
              <ul id="pagination" class="pa-0">
                <li
                  v-for="(
                    pagination, index
                  ) in listStatus.currentPaginationList"
                  :key="index + 'j'"
                  @click="loadReferList(pagination, index)"
                  :class="
                    (listStatus.currentPage - 1) % listStatus.pageCount ===
                    index
                      ? 'active'
                      : ''
                  "
                >
                  <p>{{ pagination }}</p>
                </li>
              </ul>
              <p
                class="navigation-act next"
                v-if="
                  listStatus.next > 5 &&
                  listStatus.pageCount < listStatus.totalPage &&
                  listStatus.last !== listStatus.totalPage
                "
                @click="navigatorAct('next')"
              >
                <!-- Next -->
                <v-icon> mdi-chevron-right </v-icon>
              </p>
            </v-col>
          </v-row>
        </div>
        <div class="pa-0 ma-0" v-else>
          <div class="scroll-body">
            <div class="scroll-content">
              <v-row no-gutters style="font-size: 19px; color: gray">
                <v-col cols="12">
                  <v-container fluid>
                    <v-row no-gutters>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 병원명 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="2">
                        <span> 주소 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 병원전화번호 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 팩스번호 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 의사명 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="2">
                        <span> 전문과목 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 졸업년도 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 수련병원 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 진료의뢰 </span>
                      </v-col>
                      <v-col class="grey--text text-center" cols="1">
                        <span> 보내기 </span>
                      </v-col>
                    </v-row>
                  </v-container>
                  <span style="border-bottom: 1px solid lightgray"> </span>
                </v-col>
                <v-col cols="12" class="text-center logo-wrap-col">
                  <div class="item">
                    <div class="logo-wrap">
                      <img src="@/assets/images/logo_unplugged.svg" />
                    </div>
                    <h4>조회결과 없음</h4>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="spinner" v-if="isChildLoading">
          <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
            <beat-loader
              :loading="isChildLoading"
              :color="loader.color"
              :size="loader.size"
            ></beat-loader>
          </transition>
        </div>
      </div>
      <button id="focusControl"></button>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </v-container>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import BeatLoader from "vue-spinner/src/BeatLoader";

export default {
  components: {
    DefaultPopup,
    BeatLoader,
  },
  // props: {
  //   selectItem: Number,
  // },
  data() {
    return {
      selectItem: 2,
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      currentTab: "",
      tab: [
        {
          title: "수신환자 리스트",
        },
        {
          title: "발신환자 리스트",
        },
        {
          title: "최근 진료의뢰받은 병원",
        },
        {
          title: "최근 진료의뢰한 병원",
        },
      ],
      searchOpt: {
        receiveOrder: 0, // default 옵션,
        recordsPerPage: "", // 한번에 불러올 갯수
      },
      listStatus: {
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        tempCount: null,
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        sendCount: null,
        cancelCount: null,
        /**
         * totalDataCnt: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      loader: {
        loading: false,
        color: "#146f83",
        size: "18px",
      },
      list: [],
      setCount: false,
      textName: "의뢰 받은 환자",
      textTempName: "",
      patNm: "",
      checkCount: 0,
      titleName: "수신환자 리스트 (In Box)",
    };
  },
  created() {
    window.scrollTo(0, 0);
    //console.log("this");
    //console.log(history.pushState);
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    // }
    //console.log("########################################");
    //console.log(location.href);
    //console.log("########################################");

    this.initStatement();

    //아래는 예전 방법
    //console.log("값 : " ,localStorage.getItem("currentTab", this.currentTab));
    //console.log(typeof localStorage.getItem("currentTab", this.currentTab));
    // if(localStorage.getItem("currentTab", this.currentTab) === null ||
    //   localStorage.getItem("currentTab", this.currentTab) === ""
    // ) {
    //   this.reload(0);
    // } else{
    //   //this.selectItem = Number(localStorage.getItem("currentTab", this.currentTab));
    //   this.reload(this.selectItem);
    // }
  },
  mounted() {
    //document.querySelector(".tabs__header").classList.add("transed");
    if (this.GET_LIST_ORDER !== null) {
      this.sideMenuCall(this.GET_LIST_ORDER);
    }
    this.reload(this.selectItem);
    //document.querySelector(".receive").classList.add("active");
    //console.log("==============================================");

    //console.log(this.checkCount);
  },
  updated() {
    //console.log(this.checkCount);
  },
  computed: {
    ...mapGetters("util", ["GET_EXEL_DATA"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapState("basic", ["checkPopup", "isChildLoading"]),
    ...mapGetters("referList", [
      "GET_SEND_LIST",
      "GET_RECEIVE_LIST",
      "GET_LIST_ORDER",
      "GET_RES_DOCTOR_DATA",
      "GET_REQ_DOCTOR_DATA",
      "GET_REFER_DETAIL",
    ]),
    daySet() {
      return (day) => {
        //console.log("day", day);
        if (day !== undefined) {
          let dano = "";
          dano += day.substr(0, 4);
          dano += "/";
          dano += day.substr(4, 2);
          dano += "/";
          dano += day.substr(6, 2);
          return dano;
        }
      };
    },
    ...mapGetters("request", ["GET_REFER_ID", "GET_REPLY_STATUS"]),
    getGender() {
      return (value) => {
        if (value !== null && value !== "" && value !== undefined) {
          //console.log("gender-Value", value);
          const genderCount = value.substr(7, 8);
          if (genderCount === "2" || genderCount === "4") {
            return "여";
          } else if (genderCount === "1" || genderCount === "3") {
            return "남";
          } else if (
            genderCount === "5" ||
            genderCount === "6" ||
            genderCount === "7" ||
            genderCount === "8"
          ) {
            return "외국인";
          }
        } else {
          return null;
        }
      };
    },
    getAge() {
      return (value) => {
        if (value !== null && value !== "" && value !== undefined) {
          //console.log("Age-value", value);
          let currentAge;
          const ageCount = value.substr(0, 2);
          //console.log("Age-count", ageCount);
          const now = new Date().getFullYear();
          //console.log("Age-now", now);
          const item = String(now).substr(2, 4);
          //console.log("calcu-now", item);
          if (Number(ageCount) >= Number(item)) {
            const plusSu = 100 - Number(ageCount);
            //console.log("plusSu", plusSu);
            currentAge = Number(item) + Number(plusSu);
          } else {
            currentAge = Number(item) - Number(ageCount);
          }
          return currentAge;
        } else {
          return null;
        }
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapMutations("request", ["SET_REFER_ID", "SET_REPLY_STATUS"]),
    ...mapMutations("util", ["SET_LIST_DOC_INFO"]),
    ...mapActions("referList", [
      "BRING_SEND_LIST",
      "RECEIVE_SEND_LIST",
      "REQ_DOCTOR_LIST",
      "RES_DOCTOR_LIST",
      "SAVE_MEMO",
      "SAVE_INBOX",
    ]),
    ...mapActions("join", ["FETCH_BASIC_TARGET"]),
    ...mapMutations("referList", ["SET_REFER_DETAIL"]),
    ...mapActions("util", ["BRING_RECV_EXEL", "BRING_SEND_EXEL"]),
    focusControl() {
      const focusControl = document.getElementById("focusControl");
      //console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    check_inbox_number() {
      var list_for_trans = [];
      var i = 0;
      list_for_trans = JSON.parse(JSON.stringify(this.list));

      for (i = 0; i < list_for_trans.length; i++) {
        if (list_for_trans[i].inboxYn === "Y") {
          list_for_trans.splice(i, 1);
          i--;
        }
      }
      this.checkCount = i;
    },
    async clickInBox() {
      //console.log(this.list);
      var list_for_trans = [];
      list_for_trans = JSON.parse(JSON.stringify(this.list));

      for (var i = 0; i < list_for_trans.length; i++) {
        if (list_for_trans[i].inboxYn === "Y") {
          list_for_trans.splice(i, 1);
          i--;
        }
      }
      //console.log("list_for_trans : " , list_for_trans);
      var referIdTestBoo = /^[0-9]{8,14}$/;
      var referIdBoo = false;
      for (var i = 0; list_for_trans.length > i; i++) {
        if (
          list_for_trans[i].referId === null ||
          list_for_trans[i].referId === undefined
        )
          list_for_trans[i].referId = "";

        if (!referIdTestBoo.test(list_for_trans[i].referId)) referIdBoo = true;
      }

      if (referIdBoo) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "리스트를 불러올 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      try {
        await this.SAVE_INBOX(list_for_trans).then(() => {
          this.SET_POPUP(true);
          this.popupSet.title = "진료의뢰서 보관 완료";
          this.popupSet.content =
            "진료의뢰서 보관이 완료되었습니다.\n전체 수신환자 리스트에서 확인 하실 수 있습니다.";
          this.popupSet.popType = "InBox_Restore";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.nextLink = "/v/mypage/request/0";
          this.popupSet.destination = true;
        });
      } catch (error) {
        //console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async saveMemo(id, memo, str) {
      var referParam = {};
      if (str === "RCV") {
        referParam = {
          referId: id,
          rcvMemo: memo,
          /** completed */
        };
      } else if (str === "SND") {
        referParam = {
          referId: id,
          sndMemo: memo,
          /** completed */
        };
      }

      //console.log(referParam);

      //유효성 검사
      //1. 진단의뢰번호
      if (referParam.referId === null || referParam.referId === undefined)
        referParam.referId = "";

      var referIdBoo = /^[0-9]{8,14}$/;

      if (!referIdBoo.test(referParam.referId)) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "리스트를 불러올 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //2. 진단의뢰 메모
      if (referParam.rcvMemo === null || referParam.rcvMemo === undefined)
        referParam.rcvMemo = "";

      if (referParam.rcvMemo.length < 0 || referParam.rcvMemo.length > 200) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "200자 이내로 메모를 작성해주시기 바랍니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      try {
        await this.SAVE_MEMO(referParam).then(() => {
          //console.log("done");
          this.SET_POPUP(true);
          this.popupSet.title = "메모 저장 완료";
          this.popupSet.content = "리스트 메모 저장이 완료되었습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getRcvExel() {
      try {
        await this.BRING_RECV_EXEL().then(() => {
          //console.log(this.GET_EXEL_DATA.fileName);

          const url =
            "https://www.drrefer.net/api/excels/" + this.GET_EXEL_DATA.fileName;
          //console.log("called_excels_url", url);

          window.open(url, "_blank");
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getSendExel() {
      try {
        await this.BRING_SEND_EXEL().then(() => {
          //console.log(this.GET_EXEL_DATA.fileName);

          const url =
            "https://www.drrefer.net/api/excels/" + this.GET_EXEL_DATA.fileName;
          //console.log("called_excels_url", url);

          window.open(url, "_blank");
        });
      } catch (error) {
        console.log(error);
      }
    },
    phoneFomatter(number) {
      if (number !== "" && number !== null) {
        let formatNum = "";
        if (number.length == 11) {
          formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else if (number.length == 8) {
          formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
        } else {
          //console.log("핵검증", number.indexOf("02"));
          if (number.indexOf("02") == 0) {
            if (number.length === 9) {
              formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
            } else {
              formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
            }
          } else {
            formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          }
        }
        return formatNum;
      }
    },
    async moveRequest(param, index) {
      //console.log("채소재료", param, "-", index);
      await this.FETCH_BASIC_TARGET(param).then(() => {
        const doctorInfo = {
          drId: this.GET_BASIC.drId,
          receive: this.GET_BASIC.hospNm,
          doctor: this.GET_BASIC.drNm,
          referId: null,
          proObj: this.list[index].proObj,
        };
        this.SET_REPLY_STATUS(doctorInfo);
        this.$router.push("/v/request/requestDocument").catch(() => {});
      });
    },
    writeMessage(item) {
      console.log(item);
      //console.log(this.$refs.appheader);
      const sendAppheaderItem = {
        method: "writeMessage",
        item: item,
      };
      this.$emit("send-app-header-method", sendAppheaderItem);
    },
    handleGoback() {
      if (this.setCount) {
        this.$router.push("/").catch(() => {});
      } else {
        history.go(1);
      }
      this.setCount = true;
      // 클로즈 함수 구현
    },
    initStatement() {
      //console.log("리퍼아이디 잔여물", this.GET_REFER_ID); // null
      //console.log("리퍼디테일 잔여물", this.GET_REFER_DETAIL.visitYn); //undefined
      //console.log("CHECK_REPLY_STATUS", this.GET_REPLY_STATUS.referId);
      const initState = [];
      if (this.GET_REFER_ID !== null) {
        this.SET_REFER_ID(null);
      }
      if (Object.keys(this.GET_REFER_DETAIL).length !== 0) {
        this.SET_REFER_DETAIL(initState);
      }
      if (Object.keys(this.GET_REPLY_STATUS).length !== 0) {
        this.SET_REPLY_STATUS(initState);
      }
    },
    checkDataStep() {
      this.SET_POPUP(true);
      this.popupSet.title = "부가정보 입력요청";
      this.popupSet.content =
        "PR관리에서 부가정보를 입력하면 \n진료의뢰서를 수신할 수 있는 형식과 \n병원 소개 페이지가 자동으로 완성됩니다.";
      this.popupSet.popType = "warn";
      this.popupSet.cancelBtnText = "확인";
      this.sideSet.isWritedSearch = false;
      this.sideSet.isWritedPr = false;
    },
    goPrView(drId, index) {
      const doctorInfo = {
        prevUrl: "/v/request/list",
        proObj: this.list[index].proObj,
      };
      this.SET_LIST_DOC_INFO(doctorInfo);
      //this.$router.push(`/v/pr/view/${drId}`);
      window.open(`/v/pr/view/${drId}`, "_blank");
    },
    initListStatus() {
      this.listStatus.totalDataCnt = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    async initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        /*console.log(
            "Ds",
            document.querySelector("#pagination").childNodes[k].childNodes[0]
              .textContent
          );*/
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadReferList(currentPage, order) {
      this.list = [];
      const list = [];
      this.SET_CHILD_LOADER(true);
      // this.initPaginationEffect();
      // if (this.listStatus.totalDataCnt >= 1) {
      //   document
      //     .querySelector("#pagination")
      //     .childNodes[order].classList.add("active");
      // }
      const searchOpt = {
        recordsPerPage: this.listStatus.dataOnPage,
        pageNo: currentPage,
      };

      // 유효성 검사

      //1. 페이지
      if (currentPage === null || currentPage === undefined) currentPage = "";

      var currentPageBoo = /^[0-9]{1,100}$/;

      if (!currentPageBoo.test(currentPage)) {
        this.SET_POPUP(true);
        this.popupSet.title = "페이지 요청 실패";
        this.popupSet.content = "페이지 요청에 오류가 발생하였습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //2. 페이지 당 보여줄 수
      if (
        this.listStatus.dataOnPage === null ||
        this.listStatus.dataOnPage === undefined
      )
        this.listStatus.dataOnPage = "";

      var dataOnPageBoo = /^[0-9]{1,100}$/;

      if (!dataOnPageBoo.test(this.listStatus.dataOnPage)) {
        this.SET_POPUP(true);
        this.popupSet.title = "페이지 요청 실패";
        this.popupSet.content = "페이지 요청에 오류가 발생하였습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      if (this.searchOpt.receiveOrder === 0) {
        try {
          await this.RECEIVE_SEND_LIST(searchOpt).then(() => {
            for (let i = 0; i < this.GET_RECEIVE_LIST.list.length; i++) {
              list.push(this.GET_RECEIVE_LIST.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              // this.preParePagination();
            }, 100);

            // setTimeout(() => {
            //   if (this.listStatus.totalDataCnt >= 1) {
            //     document
            //       .querySelector("#pagination")
            //       .childNodes[order].classList.add("active");
            //   }
            // }, 200);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 받은 환자";
        this.textTempName = "";
      } else if (this.searchOpt.receiveOrder === 1) {
        try {
          //console.log("===========================================");
          //console.log(searchOpt);
          await this.BRING_SEND_LIST(searchOpt).then(() => {
            for (let i = 0; i < this.GET_SEND_LIST.list.length; i++) {
              list.push(this.GET_SEND_LIST.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              // this.preParePagination();
            }, 100);

            // setTimeout(() => {
            //   if (this.listStatus.totalDataCnt >= 1) {
            //     document
            //       .querySelector("#pagination")
            //       .childNodes[order].classList.add("active");
            //   }
            // }, 200);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 보낸 환자";
        this.textTempName = "임시 저장 환자";
      } else if (this.searchOpt.receiveOrder === 2) {
        try {
          await this.RES_DOCTOR_LIST(searchOpt).then(() => {
            for (let i = 0; i < this.GET_RES_DOCTOR_DATA.list.length; i++) {
              list.push(this.GET_RES_DOCTOR_DATA.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              // this.preParePagination();
            }, 100);

            // setTimeout(() => {
            //   if (this.listStatus.totalDataCnt >= 1) {
            //     document
            //       .querySelector("#pagination")
            //       .childNodes[order].classList.add("active");
            //   }
            // }, 200);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 받은 병원";
        this.textTempName = "";
      } else if (this.searchOpt.receiveOrder === 3) {
        try {
          await this.REQ_DOCTOR_LIST(searchOpt).then(() => {
            for (let i = 0; i < this.GET_REQ_DOCTOR_DATA.list.length; i++) {
              list.push(this.GET_REQ_DOCTOR_DATA.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              // this.preParePagination();
            }, 100);

            // setTimeout(() => {
            //   if (this.listStatus.totalDataCnt >= 1) {
            //     document
            //       .querySelector("#pagination")
            //       .childNodes[order].classList.add("active");
            //   }
            // }, 200);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 보낸 병원";
        this.textTempName = "";
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalDataCnt = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      //console.log("==========================================================");
      //console.log(this.listStatus);
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      //console.log("totalDataCnt", totalDataCnt);
      //console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      //console.log("totalPage", totalPage);
      //console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      /*console.log("last : " + last);
      console.log("first : " + first);
      console.log("next :" + next);
      console.log("prev :" + prev);*/
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalDataCnt: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    async calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        await this.$nextTick((_) => {
          this.loadReferList(this.listStatus.last, 4);
        });
        // setTimeout(() => {
        //   for (
        //     let k = 0;
        //     k < document.querySelector("#pagination").childElementCount;
        //     k++
        //   ) {
        //     /*console.log(
        //       "재료",
        //       document.querySelector("#pagination").childNodes[k].childNodes[0]
        //         .textContent
        //     );*/

        //     if (
        //       Number(
        //         document.querySelector("#pagination").childNodes[k]
        //           .childNodes[0].textContent
        //       ) === Number(this.listStatus.last)
        //     ) {
        //       document
        //         .querySelector("#pagination")
        //         .childNodes[k].classList.add("active");
        //     }
        //   }
        // }, 900);
      } else if (type === "next") {
        await this.loadReferList(this.listStatus.currentPage, 0).then(() => {
          // setTimeout(() => {
          //   document
          //   .querySelector("#pagination")
          //   .childNodes[0].classList.add("active");
          // }, 900);
        });
      } else {
        if (this.list.length > 0) {
          await this.$nextTick((_) => {
            const target = document.querySelectorAll(".receive-patient-list");
            if (target !== undefined) {
              function addElsePagination(currentPage) {
                setTimeout(() => {
                  if (currentPage !== null) {
                    if (
                      document.querySelector("#pagination") === null ||
                      document.querySelector("#pagination") === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === null ||
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ].classList === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    // document
                    //   .querySelector("#pagination")
                    //   .childNodes[currentPage - 1].classList.add("active");
                  } else {
                    return addElsePagination(currentPage);
                  }
                }, 100);
              }
              addElsePagination(this.listStatus.currentPage);
            }
          });
        }
      }
    },
    navigatorAct(type) {
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      // this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
    sideMenuCall(order) {
      this.$nextTick((_) => {
        document.querySelector(".tabs__header").childNodes[order].click();
      });
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    async goDetail($event, referUrl, order, memo) {
      //console.log($event.target.id);

      if ($event.target.id.includes("memo")) {
        //console.log(memo);
      } else {
        this.SET_REFER_ID(referUrl);
        if (this.currentTab === 1) {
          if (
            this.list[order].rcvDrId === "" ||
            this.list[order].rcvDrId === null ||
            this.list[order].sndDrId === "" ||
            this.list[order].sndDrId === null ||
            this.list[order].diagNm === "" ||
            this.list[order].diagNm === null ||
            this.list[order].referConts === "" ||
            this.list[order].referConts === null ||
            this.list[order].rcvHospNm === "" ||
            this.list[order].rcvHospNm === null ||
            this.list[order].rcvDrNm === "" ||
            this.list[order].rcvDrNm === null ||
            this.list[order].rcvParts === "" ||
            this.list[order].rcvParts === null ||
            this.list[order].diagCd === "" ||
            this.list[order].diagCd === null ||
            this.list[order].patNm === "" ||
            this.list[order].patNm === null ||
            this.list[order].patBirth === "" ||
            this.list[order].patBirth === null ||
            this.list[order].patTelno === "" ||
            this.list[order].patTelno === null ||
            this.list[order].visitYn === "N"
          ) {
            this.$router.push("/v/request/requestDocument").catch(() => {});
          } else {
            this.$router.push("/v/request/requestView").catch(() => {});
          }
        } else {
          this.$router.push("/v/request/requestView").catch(() => {});
        }
      }
    },
    openMemo(index) {
      let onOffActiveList = document.querySelectorAll(".receive-patient-list");

      for (let i = 0; i < onOffActiveList.length; i++) {
        if (i !== index) {
          onOffActiveList[i].classList.remove("active");
        }
      }

      if (this.list[index].openMemo === true) {
        this.list[index].openMemo = false;
        onOffActiveList[index].classList.remove("active");
      } else {
        for (let listItem of this.list) {
          listItem.openMemo = false;
        }
        this.list[index].openMemo = true;
        onOffActiveList[index].classList.add("active");
      }
      this.list = [...this.list];
    },
    async reload(order) {
      //console.log("order", order);
      this.selectItem = order;
      this.checkCount = 0;
      this.currentTab = order;
      localStorage.setItem("currentTab", this.currentTab);
      this.initListStatus();
      this.list = [];
      const list = [];
      this.listStatus.totalDataCnt = 0;
      this.SET_CHILD_LOADER(true);
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
      };

      //console.log("1111111111111111111111 : " + order);
      if (order === 0) {
        this.titleName = "수신환자 리스트(InBox)";
        this.searchOpt.receiveOrder = 0;
        try {
          await this.RECEIVE_SEND_LIST(searchOpt).then(() => {
            //console.log(this.GET_RECEIVE_LIST);
            this.listStatus.totalDataCnt =
              this.GET_RECEIVE_LIST.param.totalCount;
            for (let i = 0; i < this.GET_RECEIVE_LIST.list.length; i++) {
              list.push(this.GET_RECEIVE_LIST.list[i]);
              list[i].openMemo = false;
            }
            this.list = list;
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.preParePagination();
            }, 100);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 받은 환자";
        this.textTempName = "";
      } else if (order === 1) {
        this.titleName = "발신환자 리스트";
        this.searchOpt.receiveOrder = 1;
        try {
          await this.BRING_SEND_LIST(searchOpt).then(() => {
            //console.log(this.GET_SEND_LIST);
            this.listStatus.totalDataCnt = this.GET_SEND_LIST.cmap.totalCount;
            this.listStatus.tempCount = this.GET_SEND_LIST.cmap.tempCount;
            this.listStatus.sendCount = this.GET_SEND_LIST.cmap.sendCount;
            this.listStatus.cancelCount = this.GET_SEND_LIST.cmap.cancelCount;
            for (let i = 0; i < this.GET_SEND_LIST.list.length; i++) {
              list.push(this.GET_SEND_LIST.list[i]);
            }
            this.list = list;
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.preParePagination();
            }, 800);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 보낸 환자";
        this.textTempName = "임시 저장 환자";
      } else if (order === 2) {
        this.titleName = "최근 진료 의뢰 받은 병원";
        this.searchOpt.receiveOrder = 2;
        try {
          await this.RES_DOCTOR_LIST(searchOpt).then(() => {
            this.listStatus.totalDataCnt =
              this.GET_RES_DOCTOR_DATA.param.totalCount;
            //console.log("진료의뢰받은 정보", this.GET_RES_DOCTOR_DATA);
            for (let i = 0; i < this.GET_RES_DOCTOR_DATA.list.length; i++) {
              list.push(this.GET_RES_DOCTOR_DATA.list[i]);
              if (
                this.GET_RES_DOCTOR_DATA.list[i].hospTel !== null &&
                this.GET_RES_DOCTOR_DATA.list[i].hospTel !== ""
              ) {
                list[0].hospTel = this.phoneFomatter(
                  this.GET_RES_DOCTOR_DATA.list[i].hospTel
                );
              }
              if (
                this.GET_RES_DOCTOR_DATA.list[i].hospFaxNo !== null &&
                this.GET_RES_DOCTOR_DATA.list[i].hospFaxNo !== ""
              ) {
                list[0].hospFaxNo = this.phoneFomatter(
                  this.GET_RES_DOCTOR_DATA.list[i].hospFaxNo
                );
              }
            }
            this.list = list;
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.preParePagination();
            }, 800);
          });
        } catch (error) {
          //console.log("api 속 에러", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 받은 병원";
        this.textTempName = "";
      } else if (order === 3) {
        this.titleName = "최근 진료 의뢰한 병원";
        this.searchOpt.receiveOrder = 3;
        try {
          await this.REQ_DOCTOR_LIST(searchOpt).then(() => {
            this.listStatus.totalDataCnt =
              this.GET_REQ_DOCTOR_DATA.param.totalCount;
            //console.log("진료의뢰한 정보", this.GET_REQ_DOCTOR_DATA);
            for (let i = 0; i < this.GET_REQ_DOCTOR_DATA.list.length; i++) {
              list.push(this.GET_REQ_DOCTOR_DATA.list[i]);
              if (
                this.GET_REQ_DOCTOR_DATA.list[i].hospTel !== null &&
                this.GET_REQ_DOCTOR_DATA.list[i].hospTel !== ""
              ) {
                list[0].hospTel = this.phoneFomatter(
                  this.GET_REQ_DOCTOR_DATA.list[i].hospTel
                );
              }
              if (
                this.GET_REQ_DOCTOR_DATA.list[i].hospFaxNo !== null &&
                this.GET_REQ_DOCTOR_DATA.list[i].hospFaxNo !== ""
              ) {
                list[0].hospFaxNo = this.phoneFomatter(
                  this.GET_REQ_DOCTOR_DATA.list[i].hospFaxNo
                );
              }
            }
            this.list = list;
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.preParePagination();
            }, 800);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 보낸 병원";
        this.textTempName = "";
      }
    },
  },
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
@import "@/assets/scss/scrollObj";
.receive-patient-list:hover {
  background-color: #f5f9fa;
}
.receive-patient-list.active {
  background-color: #f5f9fa;
}
.v-text-field--outlined fieldset {
  color: red !important;
}
.fill-width-list {
  width: 100% !important;
}
</style>
